import get from 'lodash/get';
import split from 'lodash/split';
import head from 'lodash/head';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';

import { DELIMITERS, rehydrate } from '@emobg/web-utils';
import * as UserDataModule from '@/vue/stores/UserData/UserDataStore';
import * as ProfileModule from '@Profile/store';
import * as AccountModule from '@/stores/User/Account/AccountModule';
import * as UserModule from '@/stores/User/UserModule';
import { useAuth } from '@/composable/Api/Auth/auth';
import { MFA } from '@/constants/mfa';

import config from '@/config';

const currentEnvironment = get(config, 'data.environment');

const { idpUuid } = useAuth();

export const composeUserTrackingData = ({
  trackingId, userCompanyName, currentProfile, country,
}) => ({
  user_uuid: trackingId,
  company_name: userCompanyName,
  user_profile: currentProfile,
  country,
});

export const getUserTrackingDataByGetters = ({ getters }) => ({
  user_uuid: getters[`${UserDataModule.nameSpace}/${UserDataModule.GETTERS.trackingId}`],
  company_name: getters[`${UserDataModule.nameSpace}/${UserDataModule.GETTERS.userCompanyName}`],
  user_profile: getters[`${ProfileModule.nameSpace}/${ProfileModule.GETTERS.getCurrentProfileAlias}`],
  country: getters[`${UserModule.NAMESPACE}/${AccountModule.NAMESPACE}/${AccountModule.GETTERS.getUserAddressCountry}`],
});

export const getPageTrackingData = ({
  country, language, newRoute, document, location = {},
}) => ({
  location,
  page_path: get(newRoute, 'fullPath'),
  name: get(newRoute, 'name'),
  title: get(document, 'title'),
  referrer: head(split(location, '#')),
  environment: currentEnvironment,
  language,
  country,
});

const getPersistedDataKey = () => `${MFA.extraUserMetadata}-${last(split(idpUuid, DELIMITERS.pipe))}`;

export const setUserPersistedData = ({ value }) => rehydrate.set(getPersistedDataKey(), 'data', value);

export const getUserPersistedData = () => rehydrate.get(getPersistedDataKey(), 'data');

export const getUserHasCompany = () => rehydrate.get(getPersistedDataKey(), `data.${MFA.userHasCompany}`);

export const getUserEmail = () => rehydrate.get(getPersistedDataKey(), `data.${MFA.userEmail}`);

export const setPersistentDataForUser = userData => {
  if (userData) {
    setUserPersistedData({
      value: {
        ...get(getUserPersistedData(), 'data'),
        [MFA.userHasCompany]: !isEmpty(get(userData, 'company')),
      },
    });
  }
};
