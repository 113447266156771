import { COOKIE_NAMES } from '@emobg/web-utils';
import Cookie from 'js-cookie';

const REQUEST_CODE_KEY = 'request_code';

export const getRequestCode = () => window.sessionStorage.getItem(REQUEST_CODE_KEY);

export const setRequestCode = value => window.sessionStorage.setItem(REQUEST_CODE_KEY, value);

export const removeRequestCode = () => {
  window.sessionStorage.removeItem(REQUEST_CODE_KEY);
  Cookie.remove(COOKIE_NAMES.returnUrl);
};

export const saveRequestCodeUrl = () => {
  const url = new URL(window.location.href);
  const requestCode = url.searchParams.get(REQUEST_CODE_KEY);

  if (requestCode) {
    setRequestCode(requestCode);
    Cookie.set(COOKIE_NAMES.returnUrl, url, true);
  }
};
