import isNil from 'lodash/isNil';
import result from 'lodash/result';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import get from 'lodash/get';

import { DATE_FORMAT, LOG_TYPE, logger } from '@emobg/web-utils';

const isValidMoment = date => moment.isMoment(date) && moment(date).isValid();

export const toBookingRules = datetimepickerManager => {
  const rules = result(datetimepickerManager, 'getRules');
  const minDuration = result(rules, 'getMinDuration');
  const maxDuration = result(rules, 'getMaxDuration');

  if (!rules || isNil(minDuration) || isNil(maxDuration)) {
    logger.message('Impossible to get the rules from the datetimepicker manager', LOG_TYPE.warning);
    return null;
  }

  return {
    minDuration,
    maxDuration,
  };
};

export const toDateFilters = datetimepickerManager => {
  const start = result(datetimepickerManager, 'getStart');
  const formattedStart = isValidMoment(start) ? start.format(DATE_FORMAT.filter) : null;

  const end = result(datetimepickerManager, 'getEnd');
  const formattedEnd = isValidMoment(end) ? end.format(DATE_FORMAT.filter) : null;

  if (!(formattedStart && formattedEnd)) {
    logger.message('Impossible to get the dates from the datetimepicker manager', LOG_TYPE.warning);
    return null;
  }

  return {
    start: formattedStart,
    end: formattedEnd,
  };
};

export const toBookingTimer = datetimepickerManager => {
  const rules = toBookingRules(datetimepickerManager);
  const dates = toDateFilters(datetimepickerManager);

  if (!rules) {
    logger.message('BookingTimer can not be created: Rules are not defined', LOG_TYPE.warning);
    return null;
  }

  if (!dates) {
    logger.message('BookingTimer can not be created: Dates are not defined', LOG_TYPE.warning);
    return null;
  }

  return {
    rules,
    dates,
  };
};

export const setBookingRules = (bookingTimer, { minDuration, maxDuration }) => {
  if (!isNumber(minDuration) || !isNumber(maxDuration)) {
    const rules = { minDuration, maxDuration };
    logger.message(`Booking rules are not numbers and they can not be overwritten: ${rules}`, LOG_TYPE.warning);
    return bookingTimer;
  }

  return {
    ...bookingTimer,
    rules: {
      minDuration,
      maxDuration,
    },
  };
};

export const getMinimumEnd = bookingTimer => {
  const start = get(bookingTimer, 'dates.start', null);
  const startDate = isString(start) && moment(start);
  const minDuration = get(bookingTimer, 'rules.minDuration', null);

  if (!isValidMoment(startDate) || !isNumber(minDuration)) {
    return null;
  }

  return startDate.add(minDuration, 'm').format(DATE_FORMAT.filter);
};

export const getMaximumEnd = bookingTimer => {
  const start = get(bookingTimer, 'dates.start', null);
  const startDate = isString(start) && moment(start);
  const maxDuration = get(bookingTimer, 'rules.maxDuration', null);

  if (!isValidMoment(startDate) || !isNumber(maxDuration)) {
    return null;
  }

  return startDate.add(maxDuration, 'm').format(DATE_FORMAT.filter);
};

export const isEndBetweenRules = bookingTimer => {
  const minEnd = getMinimumEnd(bookingTimer);
  const maxEnd = getMaximumEnd(bookingTimer);
  const end = get(bookingTimer, 'dates.end', null);
  const endDate = isString(end) && moment(end);

  if (!isValidMoment(endDate)) {
    logger.message('End date not found in isEndBetweenRules method', LOG_TYPE.error);
    throw new Error('End date not found');
  }

  if (!(minEnd && maxEnd)) {
    logger.message('Rules not found in isEndBetweenRules method', LOG_TYPE.error);
    throw new Error('Rules not found');
  }

  return endDate.isSameOrAfter(minEnd) && endDate.isSameOrBefore(maxEnd);
};
