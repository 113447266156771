import includes from 'lodash/includes';
import snakeCase from 'lodash/snakeCase';
import values from 'lodash/values';

import { USER_STATUS } from '@/constants/userStatus.const';

export const isValidUserStatus = userStatus => includes(values(USER_STATUS), snakeCase(userStatus));

export const isActivated = userStatus => USER_STATUS.activated === snakeCase(userStatus);

export const isDeactivated = userStatus => USER_STATUS.deactivated === snakeCase(userStatus);

export const isPreregistered = userStatus => USER_STATUS.preRegistered === snakeCase(userStatus);
