import Vue from 'vue';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import size from 'lodash/size';

import { COUNTRIES_ISO_CODES, DATE_FORMAT } from '@emobg/web-utils';

import { errorNotification } from '@/handlers/errorHandler.const';
import { ORIGIN_PLATFORM } from '@/constants/app';
import { SERVICES } from '@/constants/services.const';
import { pickUtmParameters } from '@/utils/utms';

export const createError = (notifyError, error = {}) => {
  const { text } = errorNotification(error);

  notifyError({
    delayToClose: false,
    text,
    textAction: Vue.i18n.translate('buttons.close'),
  });
};

export const generateMarketingPayload = (subscriptions, receiveNews) => {
  const subscriptionsPayload = map(subscriptions, ({ uuid, checked }) => ({ uuid, checked }));

  return subscriptions
    ? { subscriptions: subscriptionsPayload }
    : { allow_direct_marketing: receiveNews };
};

// TODO: [CF-562] Cover buildCreateAccountRequestParams with tests
/**
 * Builds a parameter object to be used in the request to create an account
 * @param {Object} state
 * @param {Object} payload
 * @param {Boolean} isCreatingUser
 * @param {Boolean} isCorporateUser
 * @param {Boolean} isItalianUser
 * @returns {Object}
 */
export const buildCreateAccountRequestParams = (state, payload, isCreatingUser, isCorporateUser, isItalianUser) => {
  const { data } = state;
  const marketingPayload = generateMarketingPayload(get(payload, 'subscriptions'), data.receiveNews);
  const utmParameters = pickUtmParameters(get(payload, 'queryString', {}));

  let finalParams = {
    allow_direct_marketing: data.receiveNews,
    email: data.email,
    first_name: data.firstName,
    id_number: data.idNumber,
    last_name: data.lastName,
    legal_document_uuids: data.legalDocumentsAccepted,
    marketing_campaign: size(utmParameters) ? utmParameters : undefined,
    origin: ORIGIN_PLATFORM,
    password: data.password,
    tax_number: data.taxNumber,
    tracking_id: data.tracking_id,
    ...marketingPayload,
  };

  if (isCreatingUser) {
    const createUserParams = {
      address: data.address.address,
      address_country_code: data.country.code === COUNTRIES_ISO_CODES.great_britain
        ? COUNTRIES_ISO_CODES.great_britain
        : data.address.countryCode,
      address_details: data.addressDetails,
      birthdate: moment(data.birthDate).format(DATE_FORMAT.date),
      language: state.locale,
      phone: data.phone,
      promo_code: data.promoCode ? data.promoCode : undefined,
      service: SERVICES.carSharing,
      service_country_code: data.country.code,
      title: data.title,
    };

    finalParams = {
      ...finalParams,
      ...createUserParams,
    };
  }

  if (isItalianUser) {
    finalParams = {
      ...finalParams,
      extra_documents: {
        ...finalParams.extra_documents,
        codice_fiscale: data.italianFiscalCode,
      },
    };
  }

  // will be deleted when we will apply on B2C same logic
  if (!isNil(isCorporateUser) && !isCorporateUser) {
    finalParams.address_country_code = data.country.code === COUNTRIES_ISO_CODES.great_britain
      ? COUNTRIES_ISO_CODES.great_britain
      : data.address.countryCode;
    finalParams.address = data.address.address;
    finalParams.birthdate = moment(data.birthDate).format(DATE_FORMAT.date);
    finalParams.phone = data.phone;
    finalParams.address_details = data.addressDetails;
  }

  return finalParams;
};
